import * as React from "react"
import axios from "axios";
import {sg_input_design, sg_label_design, sg_form_design, sg_botton_form,} from ".//../index.module.css";

const Homepageform = () => {
    const handleFocus = (event) => {
        const label = event.target.previousSibling;
        if (label) {
            label.classList.add('hidden');
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevents the form from redirecting
        const formData = new FormData(event.target);
        try {
            const response = await axios.post(
                "https://localhost/admin/preorderapi/create",
                formData
            );
            console.log(response);
            alert("success")
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <form onSubmit={handleSubmit} className={sg_form_design}>
            <div>
                <label className={sg_label_design} htmlFor="Name">نام و نام خانوادگی</label>
                <input className={sg_input_design} type="text" id="name" name="name" onFocus={handleFocus} />
            </div>
            <div>
                <label className={sg_label_design} htmlFor="Phone">تلفن</label>
                <input className={sg_input_design} type="text" id="phone" name="phone" onFocus={handleFocus} />
            </div>
            <div>
                <label className={sg_label_design} htmlFor="Email">ایمیل</label>
                <input className={sg_input_design} type="text" id="email" name="email" onFocus={handleFocus} />
            </div>
            <div>
                <label className={sg_label_design} htmlFor="Content">توضیحات</label>
                <textarea className={sg_input_design} type="text" id="content" name="content" onFocus={handleFocus} style={{height:`10vh`}}/>
            </div>

            <button type="submit" className={sg_botton_form}>ارسال پیام</button>
        </form>
    );
};

export default Homepageform;
