import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {
    sg_background_part2,
    sg_background_part3,
    sg_background_part4,
    sg_content_page1,
    two,
    there,
    sgBotton,
    one,
    sg_bghome2,
    sg_bghome1,
    sg_bghome4,
    sg_content_container,
    sg_contactform,
    sg_mapform,
    sg_text_center,
    sg_disinblock,
    sg_mapimg,
    sg_maptext,
} from ".//../components/index.module.css"
import ReactFullpage from "@fullpage/react-fullpage"
import {navigate} from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Homepageform from "../components/form/homepageform"


const IndexPage = () => (
    <Layout>

        <ReactFullpage
            //fullpage options
            licenseKey={'null'}
            scrollingSpeed={1000} /* Options here */

            render={({state, fullpageApi}) => {
                return (
                    <ReactFullpage.Wrapper>

                        <div className="section" id={there}>
                            <div className={sg_background_part3}></div>
                            <div className={sg_bghome1}></div>
                            <div className={sg_content_page1}>
                                <p style={{fontSize: `20px`}}>پشتیبان گروپ</p>
                                <p style={{fontSize: `18px`, color: `#cccccc`}}>ارائه دهنده خدمات پشتیبانی : سایت ،
                                    سیستم ، شبکه ، ویپ</p>
                                <p style={{fontSize: `16px`, color: `#fff`}}>با بیش از  14 سال سابقه در زمینه پشتیبانی
                                    سایت ، شبکه ، سیستم ،
                                </p>
                                <button type="button" className={sgBotton} onClick={() => {
                                    navigate("/")
                                }}>درباره ما
                                </button>
                            </div>
                        </div>

                        <div className="section" id={two}>
                            <div className={sg_background_part2}></div>
                            <div className={sg_bghome2}></div>
                            <div className={sg_content_page1}>
                                <p style={{fontSize: `20px`}}>پشتیبان وب</p>
                                <p style={{fontSize: `18px`, color: `#cccccc`}}>ارائه دهنده خدمات وب مستری در ایران</p>
                                <p style={{fontSize: `16px`, color: `#fff`}}>با بیش از یک یک دهه سابقه در زمینه پشتیبانی
                                    سایت ، طراحی سایت ،
                                    <br/> سئو سایت ، نگهداری سایت</p>
                                <button type="button" className={sgBotton} onClick={() => {
                                    navigate("https://supportwebs.ir")
                                }}>سایت اصلی
                                </button>

                            </div>
                        </div>

                        <div id={one} className="section">
                            <div className={sg_background_part4}></div>
                            <div className={sg_bghome4}></div>
                            <div className={sg_content_container}>
                                <div className={sg_mapform}>
                                    <div className={sg_mapimg}>
                                    <StaticImage src="../images/map1.jpg" />
                                    </div>
                                    <div className={sg_maptext}>
                                        <p> <StaticImage src="../images/icon/location-check.png" style={{verticalAlign:`middle`,marginLeft:`10px`}}/>ونک -خ ملاصدرا - خ خوارزمی -خ گلریز -
                                            خ گلسار جنوبی - ک گلبرگ 4 - پلاک 9 - واحد 1</p>
                                    </div>

                                </div>
                                <div className={sg_contactform}>
                                    <div className={sg_text_center}>
                                        <StaticImage src="../images/icon/icon-1.png" style={{verticalAlign:`middle`,marginRight:`10px`}}/>
                                        <h3 className={sg_disinblock}>تماس با<span style={{color:`#55BBCC`}}> پشتیبان گروپ</span></h3>
                                        <Homepageform />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ReactFullpage.Wrapper>
                );
            }}
        />
    </Layout>
)


export default IndexPage

export const Head = () => (

    <>
        <Seo title=" پشتیبان گروپ - ارائه دهنده خدمات پشتیبانی دیجیتال"/>
        <Seo description=" پشتیبان گروپ میزبان خدمات پشتیبانی سایت ، سیستم ، شبکه"/>

    </>
)

